import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseAPI } from '../../config'

export const utilityApi = createApi({
    reducerPath: 'utilityApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseAPI,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.user.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({

        getDipendente: builder.query({
            query: (id) => `dipendenti/${id}`
        }),

        getInformations: builder.query({
            query: () => `turni/informations`
        }),

        getDefaultOrari: builder.query({
            query: ({idServizio, idAttivita}) => `default-orari/${idServizio}-${idAttivita}`
        }),
        getExcelFerie: builder.query({
            query: () => `feriexcel`,
            forceRefetch: true
        }),
        getLavorazioni: builder.query({
            query: (id) => `lavorazione/${id}`
        }),
    })

})

export const { useGetDipendenteQuery, useGetLavorazioniQuery, useGetInformationsQuery, useGetDefaultOrariQuery, useLazyGetExcelFerieQuery } = utilityApi;
