import { createSelector } from "reselect";

export const selectFeatures = state => state.features;
export const selectError = state => state.features.error;
export const selectLoading = state => state.features.loading;
export const selectDati = createSelector(
    [selectFeatures],
    features => features.dati
);

export const selectDipendenti = createSelector(
    [selectDati],
    dati => (dati) ? dati.dipendenti : []
);

export const selectLavorazione = createSelector(
    [selectDati],
    dati => (dati) ? dati.lavorazione : []
);

export const selectAttivita = createSelector(
    [selectDati],
    dati => (dati) ? dati.attivita : []
);

export const selectFerie = createSelector(
    [selectDati],
    dati => (dati) ? dati.ferie : []
);

export const selectContenuto = createSelector(
    [selectDati],
    dati => (dati) ? dati.contenuto : []
);