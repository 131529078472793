import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectError } from '../../redux/auth/auth.selectors';
import { svuotaErrore } from '../../redux/auth/auth.slice';
import { setLogin } from '../../redux/auth/auth.thunk';

import './login.styles.scss';

const Login = () => {

    const errore = useSelector(selectError);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(svuotaErrore());
    }, [dispatch]);

    const [credentials, setCredentials] = useState({
        user: '',
        pass: ''
    });
    const [showPass, setShowPass] = useState(false);

    const handleChange = (e) => {

        const { value, id } = e.target;

        setCredentials({
            ...credentials,
            [id]: value
        });
    }

    const changeViewPass = () => setShowPass(!showPass);

    const handleSubmit = () => {
        const { user, pass } = credentials;
        dispatch(setLogin({ user, pass }))
    }

    return (
        <div className="authentication-bg bg-fondo">
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                <br />
                <br />
                <br />
                <br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-4 col-lg-5">

                            {
                                errore !== null ?
                                    <div className="row">
                                        <div className="alert alert-danger">{errore}</div>
                                    </div> : null}

                            <div className="card shadow-login">


                                <div className="card-header pt-4 pb-4 text-center bg-title-card">
                                    <a href="index.html">
                                        <img src="assets/images/logo_akrea copia.png" alt="Logo versione chiara" />
                                    </a>
                                </div>

                                <div className="card-body p-4 bg-card-login">

                                    <form action="#">

                                        <div className="mb-3">
                                            <label htmlFor="user" className="form-label text-white">Nome Utente</label>
                                            <input className="form-control" type="email" id="user" required="" onChange={handleChange} placeholder="Nome utente" />
                                        </div>

                                        <div className="mb-3">

                                            <label htmlFor="pass" className="form-label text-white">Password</label>
                                            <div className="input-group input-group-merge">
                                                <input type={showPass ? 'text' : 'password'} id="pass" className="form-control" onChange={handleChange} placeholder="Password" />
                                                <div className="input-group-text" data-password="false" onClick={changeViewPass}>
                                                    <span className="password-eye"></span>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="mb-3 mb-0 text-center">
                                            <button className="btn btn-success" type="button" onClick={handleSubmit}> Log In </button>
                                        </div>

                                    </form>
                                </div>
                            </div>


                            <div className="row mt-3">
                                <div className="col-12 text-center">

                                </div>
                            </div>


                        </div>
                    </div>

                </div>

            </div>


            <footer className="footer footer-alt">

            </footer>

            <script src="assets/js/vendor.min.js"></script>

        </div>
    );
};

export default Login;