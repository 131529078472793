import React from 'react';
import { Link } from 'react-router-dom';

import './form-ferie.styles.scss';

const FormFerie = ({ errori, handleChange, ditta, quantita, giorno, ora, tipologia, inviaDati }) => {

	return (

		<div className="form-ferie">
			<div className="form-dipendenti">
				<form action="" id="" method="post">
					<div className="row">
						<div className="col-lg-6">
							<label htmlFor="ditta" className="form-label">Nome del Produttore</label>
							<input className={`form-control ${errori.ditta ? 'is-invalid' : null}`} onChange={handleChange} id="ditta" type="text" name="ditta" value={ditta || ''} />
							<div className="invalid-feedback">
								inserire la ditta
							</div>
						</div>
						<div className="col-lg-3">
							<label htmlFor="tipologia" className="form-label">Tipologia Latte</label>
							<input className={`form-control ${errori.tipologia ? 'is-invalid' : null}`} onChange={handleChange} id="tipologia" type="text" name="tipologia" value={tipologia || ''} />
							<div className="invalid-feedback">
								inserire la tipologia
							</div>
						</div>
						<div className="col-lg-3">
							<div className="mb-3">
								<label htmlFor="quantita" className="form-label">Quantita' Totale</label>
								<input className={`form-control ${errori.quantita ? 'is-invalid' : null}`} onChange={handleChange} id="quantita" type="text" name="quantita" value={quantita || ''} />
								<div className="invalid-feedback">
									inserire la quantita
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="mb-3">
								<label htmlFor="giorno" className="form-label">Giorno</label>
								<input className={`form-control ${errori.giorno ? 'is-invalid' : null}`} onChange={handleChange} id="giorno" type="date" name="giorno" value={giorno || ''} />
								<div className="invalid-feedback">
									inserire la data
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="mb-3">
								<label htmlFor="ora" className="form-label">Ora</label>
								<input className={`form-control ${errori.ora ? 'is-invalid' : null}`} onChange={handleChange} id="ora" type="time" name="ora" value={ora || ''} />
								<div className="invalid-feedback">
									inserire la data
								</div>
							</div>
						</div>
					</div>

					<br />
					<br />

					<div className="row">
						<div className="col-lg-4"></div>
						<div className="col-lg-4 text-center">
							<Link to="/carico" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
							<button type='button' onClick={inviaDati} className="btn btn-success">Salva Dati</button>
						</div>
						<div className="col-lg-4"></div>

					</div>
				</form>
			</div>
		</div>

	);
};

export default FormFerie;