import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import {  selectLoading } from '../../../redux/features/features.selectors';
import { addDipendente } from '../../../redux/features/dipendenti/dipendenti.thunk';

import './nuovo-dipendente.styles.scss';
import FormDipendenti from '../../../components/form-dipendenti/form-dipendenti.component';
import { toast } from 'react-toastify';

const NuovoDipendente = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector(selectLoading);
	

	const [campi, setCampi] = useState({
		id: 0,
		cognome: '',
		nome: '',
		nome_breve: '',
		nascita: '',
		codice_fiscale: '',
		telefono: '',
		mail: '',
		id_mansione: 0,
		id_contratto: 0,
		data_assunzione: '',
		livello: 0,
		id_tipologia: 0,
		note: ''
	});

	const [errori, setErrori] = useState({
		nome: false,
		cognome: false
	});


	const handleChange = (e) => {
		const { value, name } = e.target;
		
		setCampi({ ...campi, [name]: value });
		setErrori({ nome: false, cognome: false});
	}

	const inviaDati = async (e) => {
		e.preventDefault();

		if (campi.nome === '') return setErrori({ ...errori, nome: true });
		if (campi.cognome === '') return setErrori({ ...errori, cognome: true });
		console.log(campi);
		const result = await dispatch(addDipendente(campi));

		if (!result.error) {
			toast.success('Dipendente inserito con successo', {
				position: 'top-right'
			})
			navigate('/dipendenti');
		} else {
			toast.error(result.payload, {
				position: 'top-right'
			})
		}
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="nuovo-dipendente">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<FormDipendenti errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NuovoDipendente;