
import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../components/loader/loader.component';
import { selectLoading } from '../../redux/features/features.selectors';

import './home.styles.scss';

const Home = () => {

	const loading = useSelector(selectLoading);



	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="content-page">
			<div className="content">


				<div className="container-fluid">

					<div className="row">
						<div className="col-8">
							<div className="page-title-box">
								<h4 className="page-title">Dashboard</h4>
							</div>
						</div>
						<div className="col-4">
							
						</div>
					</div>


					

					<br />

				

					<hr />
					<br />

				

				</div>

			</div>
			<footer className="footer">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-6">
							© Copyright 2023 - <strong className="text-primary"></strong>
						</div>
						<div className="col-md-6">
							<div className="text-md-end footer-links d-none d-md-block">
								<a href="http://">developed by <strong className="text-primary">Kromix Soc. Coop.</strong></a>
							</div>
						</div>
					</div>
				</div>
			</footer>

		</div>
	);
};

export default Home;