import React from 'react';
import MenuMobile from '../../components/menu-mobile/menu-mobile.component';
import { Link } from 'react-router-dom';

import './top-bar.styles.scss';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/auth.slice';


const TopBar = () => {

		const dispatch = useDispatch();

	return (
		<div className="navbar-custom topnav-navbar">
			<div className="container-fluid detached-nav">


				<div className="logo-topbar">

					<Link to="index.php" className="logo-light">
						<h1><img src="assets/images/logo.png" alt="Logo versione chiara" /></h1>
					</Link>


					<Link to="index.php" className="logo-dark">
						<h1><img src="assets/images/logo.png" alt="Logo versione scura" /></h1>
					</Link>
				</div>



				<MenuMobile />




				<ul className="list-unstyled topbar-menu float-end mb-0">


					<li className="dropdown notification-list">
						<Link to="#logout" onClick={() => dispatch(logout())} className="dropdown-item notify-item mt-2">
							<strong>
								<i className="mdi mdi-logout me-1"></i>
								<span>Logout</span>
							</strong>
						</Link>



					</li>
				</ul>



			</div>
		</div>
	);
};

export default TopBar;