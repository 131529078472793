import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import { selectError, selectLoading } from '../../../redux/features/features.selectors';

import './edit-ferie.styles.css';
import { toast } from 'react-toastify';
import { useGetDipendenteQuery } from '../../../redux/features/features.query';
import FormFerie from '../../../components/form-ferie/form-ferie.component';
import { editFerie } from '../../../redux/features/ferie/ferie.thunk';

const EditFerie = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const loading = useSelector(selectLoading);
	const error = useSelector(selectError);
	const { data, isFetching, isSuccess } = useGetDipendenteQuery(id, { refetchOnMountOrArgChange: true });

	//console.log(isFetching);

	const [campi, setCampi] = useState({
		id: id,
		ditta: '',
		quantita: '',
		giorno: '',
		ora: '',
		tipologia: '',
		lavorazione: '',
		giorno_lavorazione: '',
		ora_lavorazione: '',
		quantita_lavorazione: '',
		casaro: ''
	});

	const [errori, setErrori] = useState({
		ditta: false,
	});


	const handleChange = (e) => {
		const { value, name } = e.target;
		setCampi({ ...campi, [name]: value });
		setErrori({ ditta: false});
	}

	const inviaDati = async (e) => {
		e.preventDefault();

		if (campi.ditta === '') return setErrori({ ...errori, ditta: true });

		const result = await dispatch(editFerie(campi));

		if (!result.error) {
			toast.success('Carico modificato con successo', {
				position: 'top-right'
			})
			navigate('/lavorazioni');
		} else {
			toast.error(result.payload, {
				position: 'top-right'
			})
		}
	}

	useEffect(() => {
		if (isSuccess) {
			setCampi(data.dipendente);
		}
	}, [data, isSuccess]);

	return (loading || isFetching) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="edit-dipendente">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							{
								(error) ?
									(<div className="alert alert-danger" role="alert">
										{error}
									</div>)
									: null
							}
							<FormFerie errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditFerie;
