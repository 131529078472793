import { baseAPI } from "../../../config";

export const dipendenti = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "dipendenti-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const dipendentiAdd = async (token, infoDipendenti) => {
    return fetch(baseAPI + "dipendenti", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            lavorazione: infoDipendenti.lavorazione,
            giorno: infoDipendenti.giorno,
            ora: infoDipendenti.ora,
            risultati: infoDipendenti.risultati,
            casaro: infoDipendenti.casaro,
            latte: infoDipendenti.latte,
            ditta: infoDipendenti.ditta,
            quantita_totale: infoDipendenti.quantita_totale,
            quantita_lavorazione: infoDipendenti.quantita_lavorazione,
            giorno_carico: infoDipendenti.giorno_carico,
            ora_carico: infoDipendenti.ora_carico
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const dipendentiEdit = async (token, infoDipendenti) => {
    return fetch(baseAPI + "dipendenti/" + infoDipendenti.id, {
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
            ditta: infoDipendenti.ditta,
            quantita: infoDipendenti.quantita,
            giorno: infoDipendenti.giorno,
            ora: infoDipendenti.ora,
            tipologia: infoDipendenti.tipologia,
            lavorazione: infoDipendenti.lavorazione,
            giorno_lavorazione: infoDipendenti.giorno_lavorazione,
            ora_lavorazione: infoDipendenti.ora_lavorazione,
            quantita_lavorazione: infoDipendenti.quantita_lavorazione,
            casaro: infoDipendenti.casaro
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const dipendentiDelete = async (token, id) => {
    return fetch(baseAPI + "dipendenti/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const dipendentiSearch = async (token, pagina, ricerca) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "dipendenti/search-" + pagina + '?' + new URLSearchParams(ricerca), {
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        
    }).then(response => response.json());
}