import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteDipendente, getDipendente } from '../../redux/features/dipendenti/dipendenti.thunk';

import './tabella-dipendenti.styles.scss';

const TabellaDipendenti = ({ dipendenti }) => {

	const dispatch = useDispatch();

	const del = async (id) => {

		if(window.confirm("Sei sicuro di voler eliminare questa lavorazione?"))
		{
			const result = await dispatch(deleteDipendente(id));

			if(result.error)
			{
				toast.error(result.payload);
			}
			else
			{
				toast.success("Lavorazione eliminata con successo");
				dispatch(getDipendente(1));
			}
		}
	}

	return (
		<div className="tabella-dipendenti">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">

							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Produttore</th>
										<th scope="col">Tipologia di Latte</th>
										<th className="text-center" scope="col">Quantità del Carico</th>
										<th className="text-center" scope="col">Quantità Lavorata</th>
										<th className="text-center" scope="col">Giorno e Ora lavorazione</th>
										<th scope="col">Casaro</th>
										<th className="text-center" scope="col">Codice QR</th>
										<th className="text-center" scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										(dipendenti && dipendenti.length > 0) ?
											dipendenti.map((dipendente) => (
												<tr key={dipendente.id}>
													<td>{dipendente.ditta}</td>
													<td>{dipendente.tipologia}</td>
													<td className="text-center text-danger"><strong>{dipendente.quantita} (litri)</strong></td>
													<td className="text-center">{dipendente.quantita_lavorazione} (litri)</td>
													<td className="text-center">{dipendente.giorno_lavorazione} {dipendente.ora_lavorazione}</td>
													<td>{dipendente.casaro}</td>
													<td className="text-center">
														<Link to={`https://chart.googleapis.com/chart?cht=qr&chs=512x512&choe=UTF-8&chld=H&chl=https://gestione.irussocasearia.it/visualizza-lavorazione/index.php?lavorazione=${dipendente.id}_ditta=${dipendente.ditta}_tipologia=${dipendente.tipologia}_quantita=${dipendente.quantita}_quantitalavorata=${dipendente.quantita_lavorazione}_giorno=${dipendente.giorno_lavorazione}_ora=${dipendente.ora_lavorazione}_casaro=${dipendente.casaro}`} className="btn btn-info" title='Scarica QR'>Scarica QR</Link> &nbsp;
													</td>

													<td className='text-center'>
														<Link to="#dipendenti" onClick={() => del(dipendente.id)} className="btn btn-danger" title='Elimina Dipendente'><i className="uil uil-times-square"></i></Link> &nbsp;
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='7'>Nessun elemento trovato</td>
											</tr>)

									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabellaDipendenti;