import React from 'react';
import Footer from '../footer/footer.component';
import PageTitle from '../page-title/page-title.component';

import './standard-page.styles.scss';

const StandardPage = (ComponentWrappato) => {

	const TemplatePage = ({titolo}) => {

		return (
			<div className="standard-page">
				<div className="content-page">
					<div className="content">
						<div className="container-fluid">
							<PageTitle titolo={titolo} />

							<ComponentWrappato />
							<br />
						</div>
					</div>
					<Footer />
				</div>
			</div>
		);
	}

	return TemplatePage;
};

export default StandardPage;