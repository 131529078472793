import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormLavorazione from '../../../components/form-lavorazione/form-lavorazione.component';
import { selectLoading } from '../../../redux/features/features.selectors';

import './aggiungi-lavorazione.styles.css';
import { toast } from 'react-toastify';
import Loader from '../../../components/loader/loader.component';
import { addLavorazione } from '../../../redux/features/lavorazioni/lavorazioni.thunk';

const AggiungiLavorazione = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const loading = useSelector(selectLoading);


	const [campi, setCampi] = useState({

		lavorazione: '',
		giorno: '',
		ora: '',
		casaro: '',
		risultati: '',
		ditta: '',
		latte: '',
		quantita_totale: '',
		quantita_lavorazione: '',
		giorno_carico: '',
		ora_carico: ''
	});

	const [errori, setErrori] = useState({
		lavorazione: false,
	});


	const handleChange = (e) => {
		const { value, name } = e.target;
		setCampi({ ...campi, [name]: value });
		setErrori({ lavorazione: false });
	}

	const inviaDati = async (e) => {
		e.preventDefault();

		if (campi.lavorazione === '') return setErrori({ ...errori, lavorazione: true });

		const result = await dispatch(addLavorazione(campi));

		if (!result.error) {
			toast.success('Lavorazione inserita con successo', {
				position: 'top-right'
			})
			navigate('/lavorazione');
		} else {
			toast.error(result.payload, {
				position: 'top-right'
			})
		}
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (

		<div className="aggiungi-lavorazione">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<FormLavorazione errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default AggiungiLavorazione;