import { configureStore  } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import authSlice from "./auth/auth.slice";
import { combineReducers } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import featuresSlice from "./features/features.slice";
import {  utilityApi } from "./features/features.query";

const rootReducer = combineReducers({
    auth: authSlice,
    features: featuresSlice,
    [utilityApi.reducerPath]: utilityApi.reducer
})

const persistConfig = {
    key: 'akrea',
    storage,
    whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat([logger, utilityApi.middleware])
})



export const persistor = persistStore(store);

