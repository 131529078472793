import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteFerie, getFerie } from '../../redux/features/ferie/ferie.thunk';

import './tabella-ferie.styles.scss';
import { Link } from 'react-router-dom';

const TabellaFerie = ({ ferie }) => {

	const dispatch = useDispatch();

	const del = async (id) => {

		if (window.confirm("Sei sicuro di voler eliminare questo carico?")) {
			const result = await dispatch(deleteFerie(id));

			if (result.error) {
				toast.error(result.payload);
			}
			else {
				toast.success("Carico eliminato con successo");
				dispatch(getFerie(1));
			}
		}
	}

	return (

		<div className="tabella-ferie">
			<div className="row">
				<div className="col-md-12 text-end">
					<Link to='/carico/new' className="btn btn-info btn-sm btn-block">Aggiungi</Link>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<p className="text-primary font-14"><strong>Lista Carichi</strong>
							</p>
							<hr />
							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Produttore</th>
										<th scope="col">Tipologia Latte</th>
										<th className="text-center" scope="col">Quantità del Carico</th>
										<th className="text-center" scope="col">Giorno</th>
										<th className="text-center" scope="col">Ora</th>
										<th className="text-center" scope="col">Azioni</th>
									</tr>
								</thead>
								<tbody>
									{
										(ferie && ferie.length > 0) ?
											ferie.map((ferie) => (
												<tr key={ferie.id}>
													<td>{ferie.ditta}</td>
													<td>{ferie.tipologia}</td>
													<td className="text-center">{ferie.quantita} litri</td>
													<td className="text-center">{ferie.giorno}</td>
													<td className="text-center">{ferie.ora}</td>
													<td className="text-center">
														<Link to={`edit/${ferie.id}`} className="btn btn-warning" title='Modifica Carico'><i className="uil uil-edit"></i></Link> &nbsp;
														<Link to={`/dipendenti/edit/${ferie.id}`} className="btn btn-success" title='Aggiungi Lavorazione'><i className="uil uil-plus"></i></Link> &nbsp;
														<button to="#ferie" onClick={() => del(ferie.id)} className="btn btn-danger" title='Elimina Carico'><i className="uil uil-times-square"></i></button> &nbsp;
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='6'>Nessun elemento trovato</td>
											</tr>)
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default TabellaFerie;

