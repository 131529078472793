import React from 'react';
import { Link } from 'react-router-dom';

import './form-lavorazione.styles.css';

const FormLavorazione = ({ errori, handleChange, ditta, latte, lavorazione, giorno, ora, risultati, casaro, quantita_totale, giorno_carico, ora_carico, inviaDati }) => {

	return (
		<div className="form-lavorazione">
			<form action="" id="" method="post">
				<p className="text-primary font-14"><strong>Aggiungi una nuova Lavorazione</strong></p>
				<hr />
				<div className="row">
					<div className='col-lg-4'>
						<div className="mb-3">
							<label htmlFor="ditta" className="form-label">Nome del Produttore</label>
							<input className={`form-control ${errori.ditta ? 'is-invalid' : null}`} id="ditta" onChange={handleChange} type="text"name="ditta" value={ditta || ''} required />
							<div className="invalid-feedback">
								Inserire la data d'assunzione
							</div>
						</div>
					</div>
					<div className='col-lg-4'>
						<div className="mb-3">
							<label htmlFor="latte" className="form-label">Tipologia di Latte</label>
							<input className="form-control" id="latte" onChange={handleChange} type="text" name="latte" value={latte || ''} />
						</div>
					</div>
					<div className='col-lg-4'>
						<div className="mb-3">
							<label htmlFor="quantita_totale" className="form-label">Quantita Latte (litri)</label>
							<input className={`form-control ${errori.ditta ? 'is-invalid' : null}`} id="quantita_totale" onChange={handleChange} type="text"name="quantita_totale" value={quantita_totale || ''} required />
							<div className="invalid-feedback">
								Inserire la data d'assunzione
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					
					<div className='col-lg-4'>
						<div className="mb-3">
							<label htmlFor="giorno_carico" className="form-label">Giorno di arrivo</label>
							<input className="form-control" id="giorno_carico" onChange={handleChange} type="date" name="giorno_carico" value={giorno_carico || ''} />
						</div>
					</div>
					<div className='col-lg-4'>
						<div className="mb-3">
							<label htmlFor="ora_carico" className="form-label">Ora di arrivo</label>
							<input className="form-control" id="ora_carico" onChange={handleChange} type="time" name="ora_carico" value={ora_carico || ''} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="lavorazione" className="form-label">Lavorazione</label>
							<select required className="form-select" onChange={handleChange} name="lavorazione" id="lavorazione">
								<option value={lavorazione || ''}>{lavorazione || ''}</option>
								<option value="0">Seleziona</option>
								<option value="1">Lavorazione 1</option>
								<option value="2">Lavorazione 2</option>
								<option value="3">Lavorazione 3</option>
							</select>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="giorno" className="form-label">Giorno</label>
							<input className="form-control" onChange={handleChange} id="giorno" type="date" name="giorno" value={giorno || ''} />
							<div className="invalid-feedback">
								inserire la data
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="ora" className="form-label">Ora</label>
							<input className="form-control" onChange={handleChange} id="ora" type="time" name="ora" value={ora || ''} />
							<div className="invalid-feedback">
								inserire la data
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="casaro" className="form-label">Casaro</label>
							<input className="form-control" id="casaro" onChange={handleChange} type="text" name="casaro" value={casaro || ''} />
						</div>
					</div>
					<div className="col-lg-8">
						<div className="mb-3">
							<label htmlFor="risultati" className="form-label">Risultati</label>
							<input className="form-control" id="risultati" onChange={handleChange} type="text" name="risultati" value={risultati || ''} />
						</div>
					</div>

				</div>

				<br />
				<br />

				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 text-center">
						<Link to="/lavorazione" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
						<button type='button' onClick={inviaDati} className="btn btn-success">Salva Dati</button>
					</div>
					<div className="col-lg-4"></div>

				</div>
			</form>
		</div>

	);
};

export default FormLavorazione;