import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import { selectError, selectLoading } from '../../../redux/features/features.selectors';
import { editLavorazione } from '../../../redux/features/lavorazioni/lavorazioni.thunk';

import './edit-lavorazione.styles.css';
import { toast } from 'react-toastify';
import { useGetLavorazioniQuery } from '../../../redux/features/features.query';
import FormLavorazione from '../../../components/form-lavorazione/form-lavorazione.component';

const EditDipendente = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const loading = useSelector(selectLoading);
	const error = useSelector(selectError);
	const { data, isFetching, isSuccess } = useGetLavorazioniQuery(id, { refetchOnMountOrArgChange: true });

	//console.log(isFetching);

	const [campi, setCampi] = useState({
		id: id,
		lavorazione: '',
		giorno: '',
		ora: '',
		risultati: '',
		casaro: '',
		latte: '',
		ditta: ''
	});

	const [errori, setErrori] = useState({
		lavorazione: false,
	});


	const handleChange = (e) => {
		const { value, name } = e.target;
		setCampi({ ...campi, [name]: value });
		setErrori({ lavorazione: false});
	}

	const inviaDati = async (e) => {
		e.preventDefault();

		if (campi.lavorazione === '') return setErrori({ ...errori, lavorazione: true });

		const result = await dispatch(editLavorazione(campi));

		if (!result.error) {
			toast.success('Dipendente modificato con successo', {
				position: 'top-right'
			})
			navigate('/lavorazione');
		} else {
			toast.error(result.payload, {
				position: 'top-right'
			})
		}
	}

	useEffect(() => {
		if (isSuccess) {
			setCampi(data.lavorazione);
		}
	}, [data, isSuccess]);

	return (loading || isFetching) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="edit-lavorazione">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">


							<p className="text-primary font-14"><strong>Visualizza i dati della Lavorazione</strong></p>
							<hr />
							{
								(error) ?
									(<div className="alert alert-danger" role="alert">
										{error}
									</div>)
									: null
							}
							<FormLavorazione errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditDipendente;
