import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuBar from '../../components/menu-bar/menu-bar.component';
import TopBar from '../../components/top-bar/top-bar.component';
import Home from '../home/home.component';
import NuovoDipendente from '../lista-dipendenti/nuovo-dipendente/nuovo-dipendente.component';
import EditDipendente from '../lista-dipendenti/edit-dipendente/edit-dipendente.component';
import EditFerie from '../ferie/edit-ferie/edit-ferie.component';
import ListaDipendenti from '../lista-dipendenti/lista-dipendenti.component';
import FerieDipendenti from '../ferie/ferie.component';
import NuovoFerieDipendenti from '../ferie/aggiungi-ferie/aggiungi-ferie.component';
import StandardPage from '../../components/standard-page/standard-page.component';
import Personal from '../personal/personal.component';


import './wrapper.styles.scss';
import Lavorazione from '../lavorazione/lavorazione.component';
import AggiungiLavorazione from '../lavorazione/aggiungi-lavorazione/aggiungi-lavorazione.component';
import EditLavorazione from '../lavorazione/edit-lavorazione/edit-lavorazione.component';

const LavorazionePage = StandardPage(Lavorazione);
const NuovaLavorazionePage = StandardPage(AggiungiLavorazione);
const EditLavorazionePage = StandardPage(EditLavorazione);

const FerieDipendentiPage = StandardPage(FerieDipendenti);
const NuovoFerieDipendentiPage = StandardPage(NuovoFerieDipendenti);

const ListaDipendentiPage = StandardPage(ListaDipendenti);
const NuovoDipendentePage = StandardPage(NuovoDipendente);
const EditDipendentePage = StandardPage(EditDipendente);
const EditFeriePage = StandardPage(EditFerie);
 
const Wrapper = () => {
	return (
		<div className="wrapper">
			<TopBar />
			<MenuBar />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/lavorazioni' element={<ListaDipendentiPage titolo="Elenco Lavorazioni" />} />	

				<Route path='/lavorazione' element={<LavorazionePage titolo="Gestione Lavorazione" />} />	
				<Route path='/lavorazione/new' element={<NuovaLavorazionePage titolo="Nuova Lavorazione" />} />	
				<Route path='/lavorazione/edit/:id' element={<EditLavorazionePage titolo="Visualizza Lavorazione" />} />	

				<Route path='/carico' element={<FerieDipendentiPage titolo="Gestione Carico" />} />	
				<Route path='/carico/new' element={<NuovoFerieDipendentiPage titolo="Inserisci nuovo Carico" />} />	
				<Route path='/carico/edit/:id' element={<EditFeriePage titolo="Modifica Carico" />} />	

				<Route path='/dipendenti/edit/:id' element={<EditDipendentePage titolo="Visualizza Lavorazione" />} />	
				<Route path='/dipendenti/new' element={<NuovoDipendentePage titolo="Nuovo Dipendente" />} />
				<Route path='/personal' element={<Personal />} />
			</Routes>
		</div>
	);
};

export default Wrapper;