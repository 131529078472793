import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import './tabella-lavorazione.styles.scss';
import { Link } from 'react-router-dom';
import { deleteLavorazione, getLavorazione } from '../../redux/features/lavorazioni/lavorazioni.thunk';

const TabellaLavorazione = ({ lavorazioni }) => {

	const dispatch = useDispatch();

	const del = async (id) => {

		if (window.confirm("Sei sicuro di voler eliminare questa lavorazione?")) {
			const result = await dispatch(deleteLavorazione(id));

			if (result.error) {
				toast.error(result.payload);
			}
			else {
				toast.success("Lavorazione eliminato con successo");
				dispatch(getLavorazione(1));
			}
		}
	}

	return (

		<div className="tabella-ferie">
			<div className="row">
				<div className="col-md-12 text-end">
					<Link to='/lavorazione/new' className="btn btn-info btn-sm btn-block">Aggiungi</Link>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<p className="text-primary font-14"><strong>Lista Lavorazioni</strong>
							</p>
							<hr />
							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Lavorazione</th>
										<th scope="col">Giorno</th>
										<th scope="col">Ora</th>
										<th scope="col">Casaro</th>
										<th scope="col">Risultati</th>
										<th scope="col">QR Code</th>
										<th className="text-center" scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										(lavorazioni && lavorazioni.length > 0) ?
											lavorazioni.map((lavorazioni) => (
												<tr key={lavorazioni.id}>
													<td>{lavorazioni.lavorazione}</td>
													<td>{lavorazioni.giorno}</td>
													<td>{lavorazioni.ora}</td>
													<td>{lavorazioni.casaro}</td>
													<td>{lavorazioni.risultati}</td>
													<td>
														<Link to={`https://chart.googleapis.com/chart?cht=qr&chs=512x512&chl=https://gestione.irussocasearia.it/visualizza-lavorazione/index.php?lavorazione=${lavorazioni.lavorazione}giorno=${lavorazioni.giorno}&ora=${lavorazioni.ora}&casaro=${lavorazioni.casaro}&risultati=${lavorazioni.risultati}&ditta=${lavorazioni.ditta}&latte=${lavorazioni.latte}`} target="_blank" className="btn btn-info" title='Scarica QR'>Scarica QR</Link> &nbsp;
													</td>
													<td>
														<Link to={`/dipendenti/edit/${lavorazioni.id}`} className="btn btn-warning" title='Visualizza Lavorazione'><i className="uil uil-edit"></i></Link> &nbsp;
														<button to="#lavorazione" onClick={() => del(lavorazioni.id)} className="btn btn-danger" title='Elimina Lavorazione'><i className="uil uil-times-square"></i></button> &nbsp;
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='6'>Nessun elemento trovato</td>
											</tr>)
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default TabellaLavorazione;