import { baseAPI } from "../../../config";

export const lavorazione = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "lavorazione-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const lavorazioneAdd = async (token, infoLavorazione) => {
    return fetch(baseAPI + "lavorazione", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            lavorazione: infoLavorazione.lavorazione,
            giorno: infoLavorazione.giorno,
            ora: infoLavorazione.ora,
            risultati: infoLavorazione.risultati,
            casaro: infoLavorazione.casaro,
            latte: infoLavorazione.latte,
            ditta: infoLavorazione.ditta
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const lavorazioneEdit = async (token, infoLavorazione) => {
    return fetch(baseAPI + "lavorazione/" + infoLavorazione.id, {
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
            lavorazione: infoLavorazione.lavorazione,
            giorno: infoLavorazione.giorno,
            ora: infoLavorazione.ora,
            risultati: infoLavorazione.risultati,
            casaro: infoLavorazione.casaro,
            latte: infoLavorazione.latte,
            ditta: infoLavorazione.ditta
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const lavorazioneDelete = async (token, id) => {
    return fetch(baseAPI + "lavorazione/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}