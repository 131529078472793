import React, { useEffect, useState } from 'react';
import TabellaFerie from '../../components/tabella-ferie/tabella-ferie.component';
import Loader from '../../components/loader/loader.component';
import { getFerie } from '../../redux/features/ferie/ferie.thunk';
import { selectDati, selectLoading, selectContenuto } from '../../redux/features/features.selectors';

import './ferie.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getPagination } from '../../utilities/utility';

const Ferie = () => {
	const dispatch = useDispatch();
	const dati = useSelector(selectDati);
	const ferie = useSelector(selectContenuto);
	const loading = useSelector(selectLoading); 

	const pagine = getPagination(dati?.pagina, dati?.pagine, 4);
	const [active, setActive] = useState(1);

	const paginate = (pagina) => {
		setActive(pagina);
		dispatch(getFerie(pagina));
	}

	useEffect(() => {
		dispatch(getFerie(1));
	}, [dispatch]);

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		(dati) ? (

			<div className="ferie">

				<div className="row">
					<div className="col-lg-12">
						<TabellaFerie ferie={ferie} />
					</div>
				</div>

				<div className="row">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<nav>
							<ul className="pagination pagination-rounded mb-0 justify-content-center">

								<li className="page-item">
									<button className="page-link" onClick={pagine.first ? () => paginate(pagine.first) : null} aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</button>
								</li>
								{
									pagine.before.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}
								<li className={"page-item " + ((active === dati?.pagina) ? 'active' : null)}><button className="page-link">{dati?.pagina}</button></li>
								{
									pagine.after.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}

								<li className="page-item">
									<button className="page-link" onClick={pagine.last ? () => paginate(pagine.last) : null} aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</button>
								</li>
							</ul>
						</nav>
					</div>
					<div className="col-md-4"></div>
				</div>
			</div>

		) : null
	);
};

export default Ferie;