import React from 'react';
import { Link } from 'react-router-dom';

import './form-dipendenti.styles.scss';

const FormDipendenti = ({ errori, handleChange, ditta, quantita, giorno, ora, tipologia, lavorazione, giorno_lavorazione, ora_lavorazione, quantita_lavorazione, casaro, inviaDati }) => {
	return (
		<div className="form-dipendenti">
			<form action="" id="" method="post">

				<div className="row">
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="ditta" className="form-label">Nome del Produttore</label>
							<input className={`form-control ${errori.ditta ? 'is-invalid' : null}`} id="ditta" onChange={handleChange} type="text" name="ditta" value={ditta || ''} required />
							<div className="invalid-feedback">
								Inserire la data d'assunzione
							</div>
						</div>
					</div>

					<div className="col-lg-4">
						<label htmlFor="tipologia" className="form-label">Tipologia di Latte</label>
						<input className={`form-control ${errori.latte ? 'is-invalid' : null}`} id="tipologia" onChange={handleChange} type="text" name="tipologia" value={tipologia || ''} required />
						<div className="invalid-feedback">
							Inserire il cognome
						</div>
					</div>

					<div className="col-lg-4">
						<label htmlFor="quantita" className="form-label text-danger"><strong>Quantita Latte Totale (litri)</strong></label>
						<input className={`form-control ${errori.quantita ? 'is-invalid' : null}`} id="quantita" onChange={handleChange} type="text" name="quantita" value={quantita || ''} required />
						<div className="invalid-feedback">
							Inserire il cognome
						</div>
					</div>
				</div>

				<hr />

				<div className="row">
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="lavorazione" className="form-label">Lavorazione</label>
							<select className="form-select" onChange={handleChange} id="lavorazione" name="lavorazione">
								<option value="0">{lavorazione || 'Seleziona'}</option>
								<option value="lavorazione 1">lavorazione 1</option>
								<option value="lavorazione 2">lavorazione 2</option>
								<option value="lavorazione 3">lavorazione 3</option>
							</select>
						</div>
					</div>
					<div className="col-lg-4">
						<label htmlFor="quantita_lavorazione" className="form-label">Quantità Lavorata (litri)</label>
						<input className={`form-control ${errori.giorno ? 'is-invalid' : null}`} id="quantita_lavorazione" onChange={handleChange} type="text" name="quantita_lavorazione" value={quantita_lavorazione || ''} required />
						<div className="invalid-feedback">
							Inserire il cognome
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4">
						<label htmlFor="giorno_lavorazione" className="form-label">Giorno della Lavorazione</label>
						<input className={`form-control ${errori.giorno ? 'is-invalid' : null}`} id="giorno_lavorazione" onChange={handleChange} type="date" name="giorno_lavorazione" value={giorno_lavorazione || ''} required />
						<div className="invalid-feedback">
							Inserire il cognome
						</div>
					</div>
					<div className="col-lg-4">
						<label htmlFor="ora_lavorazione" className="form-label">Ora</label>
						<input className={`form-control ${errori.ora ? 'is-invalid' : null}`} id="ora_lavorazione" onChange={handleChange} type="time" name="ora_lavorazione" value={ora_lavorazione || ''} required />
						<div className="invalid-feedback">
							Inserire il cognome
						</div>
					</div>
					<div className="col-lg-4">
						<label htmlFor="casaro" className="form-label">Casaro</label>
						<input className={`form-control ${errori.casaro ? 'is-invalid' : null}`} id="casaro" onChange={handleChange} type="text" name="casaro" value={casaro || ''} required />
						<div className="invalid-feedback">
							Inserire il cognome
						</div>
					</div>
				</div>

				<br />
				<br />

				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 text-center">
						<Link to="/lavorazione" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
						<button type='button' onClick={inviaDati} className="btn btn-success">Salva Dati</button>
					</div>
					<div className="col-lg-4"></div>

				</div>
			</form >
		</div >
	);
};

export default FormDipendenti;