import React from 'react';
import Footer from '../../components/footer/footer.component';

import './personal.styles.scss';

const Personal = () => {
	return (
		<div className="personal">
			<div className="content-page">
				<div className="content">


					<div className="container-fluid">

						<div className="row">
							<div className="col-12 text-center">
								<div className="page-title-box">
									<h4 className="page-title">Calendario Attivita'</h4>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<a href="http://">
									<div className="card border-danger border">
										<div style={{ paddingBottom: "30px" }} className="card-body text-center">
											<h5 className="card-title text-danger"><br />Giorno Precedente</h5>
										</div>
									</div>
								</a>
							</div>
							<div className="col-6">
								<a href="http://">
									<div className="card border-success border">
										<div style={{ paddingBottom: "30px" }} className="card-body text-center">
											<h5 className="card-title text-success"><br />Giorno Successivo </h5>
										</div>
									</div>
								</a>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12">
								<div className="card text-white bg-warning">
									<div className="card-body text-center">
										<h2>Martedi 21 giugno 2022</h2>
									</div>
								</div>
							</div>
						</div>

						<hr />
						<br />

						<div className="card border-primary border">
							<div className="card-body">
								<h5 className="card-title text-primary">Servizio: <span className="text-danger">Capannone Akrea</span></h5>
								<h5 className="card-title text-primary">Attivita': <span className="text-danger">Area Capannone - Responsabile</span></h5>
								<hr />
								<p className="card-text">
									Ora Inizio: <span className="text-danger"><strong>04:00</strong></span> - Ora Fine: <span className="text-danger"><strong>12:00</strong></span><br />
									
									Automezzo: <span className="text-danger"><strong>Nome Automezzo - GH406EP</strong></span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default Personal;