import { createAsyncThunk } from "@reduxjs/toolkit";
import { logout } from "../../auth/auth.slice";
import { lavorazioneAdd, lavorazioneDelete, lavorazione, lavorazioneEdit } from "./lavorazioni.api";

export const addLavorazione = createAsyncThunk(
    'features/addLavorazione',
    async (infoLavorazione, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await lavorazioneAdd(stato.auth.user.token, infoLavorazione);
        if (response.esito === 1) {
            return response;
        }
        else if(response.esito === 0 && response.errorCode === 2)
        {
            return rejectWithValue('Lavorazione gia esistente');
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const editLavorazione = createAsyncThunk(
    'features/editLavorazione',
    async (infoLavorazione, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await lavorazioneEdit(stato.auth.user.token, infoLavorazione);
        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const getLavorazione = createAsyncThunk(
    'features/lavorazione',
    async (pagina, { getState, rejectWithValue, dispatch }) => {

        const stato = getState();
        const response = await lavorazione(stato.auth.user.token, pagina);

        if (response.esito === 1) {
            return response;
        }
        else {
            if(response.code === 401)
            {
                dispatch(logout());
            }
            return rejectWithValue(response.error);
        }
    }
)

export const deleteLavorazione = createAsyncThunk(
    'features/deleteLavorazione',
    async (id, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await lavorazioneDelete(stato.auth.user.token, id);
        if (response.esito === 1) {
            return response;
        }
        else if (response.esito === 2) {
            return rejectWithValue("Impossibile eliminare la Lavorazione");
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)
