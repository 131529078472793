import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './menu-bar.styles.scss';

const MenuBar = () => {

	const {pathname} = useLocation();

	return (
		<div className="leftside-menu">


			<Link to="/" className="logo logo-light">

			<h1><img src="assets/images/logo_akrea.png" alt="Logo versione chiara" /></h1>

			</Link>


			<Link to="/" className="logo logo-dark">
			<h1><img src="assets/images/logo_akrea.png" alt="Logo versione chiara" /></h1>
			</Link>


			<button type="button" className="btn button-sm-hover p-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
				<i className="ri-checkbox-blank-circle-line align-middle"></i>
			</button>


			<div className="h-100" id="leftside-menu-container" data-simplebar>


				<ul className="side-nav">
					<br />
					<li className={`side-nav-item ${pathname === '/' ? 'active' : null}`}>
						<Link to="/" className="side-nav-link">
							<i className="uil-calender"></i>
							<span> Dashboard</span>
						</Link>
					</li>

					

					<li className={`side-nav-item ${pathname === '/carico' ? 'active' : null}`}>
						<Link to="/carico" className="side-nav-link">
							<i className="uil-copy-alt"></i>
							<span> Gestione Carico </span>
						</Link>
					</li>

					<li className={`side-nav-item ${pathname === '/lavorazioni' ? 'active' : null}`}>
						<Link to="/lavorazioni" className="side-nav-link">
							<i className="uil-file-alt"></i>
							<span> Gestione Lavorazione </span>
						</Link>
					</li>



					<li className="side-nav-title side-nav-item"></li>
					<li className="side-nav-title side-nav-item"></li>
					<li className="side-nav-title side-nav-item"></li>
					<li className="side-nav-title side-nav-item"></li>
					<li className="side-nav-title side-nav-item"></li>


				</ul>

				<div className="clearfix"></div>
			</div>

			
		</div>
	);
};

export default MenuBar;