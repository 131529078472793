import { createSlice } from "@reduxjs/toolkit";
import { getDipendente } from "./dipendenti/dipendenti.thunk";
import { getFerie } from "./ferie/ferie.thunk";
import { getLavorazione } from "./lavorazioni/lavorazioni.thunk";

export const featuresSlice = createSlice({
    name: 'features',
    initialState: {
        error: null,
        loading: false,
        dati: null,
    },
    reducers: {

    },
    extraReducers: {
        [getFerie.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getFerie.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getFerie.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [getLavorazione.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getLavorazione.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getLavorazione.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [getDipendente.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getDipendente.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getDipendente.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        }
    }
})

export default featuresSlice.reducer;