import { baseAPI } from "../../../config";

export const ferie = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "ferie-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const ferieAdd = async (token, infoFerie) => {
    return fetch(baseAPI + "ferie", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            ditta: infoFerie.ditta,
            quantita: infoFerie.quantita,
            giorno: infoFerie.giorno,
            ora: infoFerie.ora,
            tipologia: infoFerie.tipologia,
            lavorazione: infoFerie.lavorazione,
            giorno_lavorazione: infoFerie.giorno_lavorazione,
            ora_lavorazione: infoFerie.ora_lavorazione,
            quantita_lavorazione: infoFerie.quantita_lavorazione,
            casaro: infoFerie.casaro
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const ferieEdit = async (token, infoFerie) => {
    return fetch(baseAPI + "ferie/" + infoFerie.id, {
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
            ditta: infoFerie.ditta,
            quantita: infoFerie.quantita,
            giorno: infoFerie.giorno,
            ora: infoFerie.ora,
            tipologia: infoFerie.tipologia,
            lavorazione: infoFerie.lavorazione,
            giorno_lavorazione: infoFerie.giorno_lavorazione,
            ora_lavorazione: infoFerie.ora_lavorazione,
            quantita_lavorazione: infoFerie.quantita_lavorazione,
            casaro: infoFerie.casaro
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const ferieDelete = async (token, id) => {
    return fetch(baseAPI + "ferie/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}